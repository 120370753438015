// src/FileUpload.js
import React, { useState } from 'react';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { storage } from './firebaseConfig'; // Import Firebase configuration
import '../style/FileUpload.css'; // Add a CSS file for styling
import shieldTickSafe from '../assets/shield-tick-safe.svg';
import img from '../assets/Frame.png'

const FileUpload = () => {
  const [file, setFile] = useState(null);
  const [progress, setProgress] = useState(0);
  const [url, setUrl] = useState('');

  // Handle file selection
  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  // Handle file upload
  const handleUpload = () => {
    if (!file) return;

    const storageRef = ref(storage, `testings3/${file.name}`); // Save file in 'testings3' folder
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
        setProgress(progress);
      },
      (error) => {
        console.error("Upload failed:", error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setUrl(downloadURL);
          console.log("File available at", downloadURL);
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        });
      }
    );
  };

  return (
    <div className="main-container">
      <div className="image-container">
      <svg xmlns="http://www.w3.org/2000/svg" width="235" height="51" viewBox="0 0 235 51" fill="none" className='svgicon'>
  <path d="M234.406 8.31939C234.415 9.17167 233.908 9.94464 233.122 10.275C232.336 10.6053 231.429 10.4273 230.826 9.82461C230.224 9.22192 230.046 8.31445 230.376 7.52874C230.706 6.74303 231.479 6.23538 232.331 6.2444C232.885 6.23208 233.42 6.44674 233.812 6.83858C234.204 7.23042 234.419 7.76539 234.406 8.31939Z" fill="#444444"/>
  <path d="M233.091 50.0106L233.091 50.7314H232.368H231.54C231.54 50.7314 231.54 43.1627 231.54 41.1798V16.3458V15.6118H231.769H232.159H233.049V16.366V41.4698C233.049 43.3448 233.024 48.7127 233.091 49.6502V50.0106Z" fill="#444444"/>
  <path d="M196.375 47.1028C196.389 48.3951 195.619 49.5671 194.428 50.0679C193.237 50.5688 191.861 50.2989 190.947 49.3851C190.033 48.4713 189.763 47.0953 190.264 45.904C190.765 44.7126 191.937 43.9429 193.229 43.9566C194.069 43.9379 194.88 44.2634 195.474 44.8575C196.069 45.4516 196.394 46.2628 196.375 47.1028Z" fill="#0A8394"/>
  <path d="M223.508 41.4752L221.968 45.0141C219.496 48.9777 215.349 51 210.601 51C203.915 51 199.795 47.8655 199.795 41.5628C199.795 33.3593 210.23 29.8204 221.191 29.8204H223.467V28.0812C223.467 26.4095 223.467 25.0748 223.332 23.5379C222.866 18.2666 219.388 15.449 213.634 15.449C208.69 15.449 203.408 18.0509 202.605 24.5895H201.132C201.537 18.7183 206.684 14.0469 213.634 14.0469C220.584 14.0469 224.332 17.4173 224.805 23.3222C224.94 25.122 224.94 26.059 224.94 27.9262V38.7115C224.94 42.7155 225.144 46.1234 225.549 50.7273H223.988L223.508 41.4752ZM223.44 36.1365V31.337C213.141 30.6697 201.396 33.5345 201.396 41.5628C201.396 46.4364 204.401 49.6518 210.851 49.6518C218.355 49.6518 223.44 43.0795 223.44 36.1702V36.1365Z" fill="#444444"/>
  <path d="M82.6417 36.3736V50.7295H81.1719V1.66064H82.6417V34.6407L103.333 15.6119H105.538L86.8487 32.638L105.605 50.7295H103.401L85.6486 33.636L82.6417 36.3736Z" fill="#444444"/>
  <path d="M124.069 14.7567V16.105C117.678 15.5657 113.211 19.9073 113.211 31.5165V50.7302H111.751V15.6106H113.16L113.211 23.1636L114.611 19.159C116.312 16.4051 119.303 14.7178 122.535 14.6893C123.048 14.6764 123.56 14.699 124.069 14.7567Z" fill="#444444"/>
  <path d="M151.023 41.4752L149.483 45.0141C147.011 48.9777 142.864 51 138.116 51C131.43 51 127.31 47.8655 127.31 41.5628C127.31 33.3593 137.745 29.8204 148.707 29.8204H150.983V28.0812C150.983 26.4095 150.983 25.0748 150.848 23.5379C150.381 18.2666 146.903 15.449 141.149 15.449C136.205 15.449 130.923 18.0509 130.12 24.5895H128.647C129.053 18.7183 134.199 14.0469 141.149 14.0469C148.099 14.0469 151.847 17.4173 152.32 23.3222C152.455 25.122 152.455 26.059 152.455 27.9262V38.7115C152.455 42.7155 152.566 46.1234 152.971 50.7273H151.516L151.023 41.4752ZM150.956 36.1365V31.337C140.656 30.6697 128.911 33.5345 128.911 41.5628C128.911 46.4364 131.916 49.6518 138.366 49.6518C145.87 49.6518 150.956 43.0795 150.956 36.1702V36.1365Z" fill="#444444"/>
  <path d="M164.036 9.88684V15.5195H171.168V16.9226H164.036V50.7283H162.595V16.9024H158.797V15.5195H162.595V9.88684C162.58 8.70706 162.625 7.52727 162.73 6.35208C163.127 1.94038 166.097 0.00436229 168.791 0.00436229C169.845 -0.0243859 170.898 0.0890602 171.922 0.341648V1.73801C166.723 0.469817 164.514 3.01295 164.191 6.54771C164.081 7.65726 164.029 8.77185 164.036 9.88684Z" fill="#444444"/>
  <path d="M186.524 49.3365V50.5216C185.432 50.6677 184.331 50.736 183.23 50.7259C180.166 50.7259 178.538 49.2411 178.274 46.1421C178.143 44.5211 178.143 43.3769 178.143 41.3744V16.2963H174.037V15.1044H178.143V7.80298H179.579V15.1044H186.333V16.3167H179.612V41.6673C179.612 43.5608 179.612 44.5688 179.678 45.5155C179.935 48.9959 181.437 50.0789 186.524 49.3365Z" fill="#444444"/>
  <path d="M30.9961 50.7283H23.7457V20.3153H11.071V50.7283H3.8004V20.3153H0V15.6087H3.8004V14.3696C3.8004 13.0941 3.8004 11.4271 3.93516 10.2258C4.60225 3.67942 8.94172 0.00130392 19.1435 0.00130392C20.6868 -0.0148143 22.2281 0.118533 23.7457 0.399485V5.87954C14.8108 5.20466 11.8055 6.68265 11.2732 10.6037C11.1375 11.7234 11.07 12.8503 11.071 13.9781V15.6087H30.9961V50.7283Z" fill="#0A8394"/>
  <path d="M71.9929 30.6516V50.7252H64.6503V31.4808C64.6503 29.8157 64.6503 28.2788 64.5155 26.479C64.1851 22.4748 62.0477 19.6706 57.0448 19.6706C52.0418 19.6706 47.8278 23.6747 47.8278 34.7501V50.712H40.4919V15.6082H47.8278V21.0727L50.2349 17.0281C53.0758 13.6572 55.6289 14.2913 59.1754 14.2913C66.7877 14.2913 71.1231 18.228 71.7906 24.4364C71.9255 26.2093 71.9929 27.8137 71.9929 30.6516Z" fill="#0A8394"/>
</svg>
        <img src={img} alt="Background" className="upload-image main-image" />
      </div>
      <div className="text-container">
        <h2 className='header' >Please upload your file here</h2> {/* Text above upload section */}
        <input type="file" onChange={handleFileChange} />
        <button onClick={handleUpload}>Upload</button>
        {progress > 0 && <p>Uploading: {progress}%</p>}
        {url && (
          <div>
            <p>File uploaded successfully!</p>
            <a href={url} target="_blank" rel="noopener noreferrer">View File</a>
          </div>
        )}

        {/* Position the authentication message below the choose file box */}
        <div className='authentication'>
          <img src={shieldTickSafe} alt="Shield Tick Safe" className='Shield Tick Safe' />
          <span>No spams, your information is safe with us!</span>
        </div>
        
        {/* Certification logos added here */}
        <div className="certification-container">
          <img src="/image 109.png" alt="Certification 1" className="certification-logo" />
          <img src="/image 110.png" alt="Certification 2" className="certification-logo" />
          <img src="/image 111.png" alt="Certification 3" className="certification-logo" />
        </div>
      </div>

    </div>
  );
};

export default FileUpload;


