import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signInAnonymously,
  signInWithPopup,
  GoogleAuthProvider,
} from 'firebase/auth';
import { auth } from './firebase'; // Ensure the path to firebase.js is correct
import '../style/Login.css';
import loginimg from '../assets/Group 1227.png';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isRegistering, setIsRegistering] = useState(false); // Toggle between login and register mode
  const [emailError, setEmailError] = useState(''); // For email-specific errors
  const [passwordError, setPasswordError] = useState(''); // For password-specific errors
  const [error, setError] = useState(''); // General error
  const navigate = useNavigate();

  // Handle email/password login
  const handleLogin = async (e) => {
    e.preventDefault();
    resetErrors();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate('/upload'); // Redirect to the upload page after successful login
    } catch (err) {
      handleFirebaseError(err.code); // Pass error code
    }
  };

  // Handle registration with email/password
  const handleRegister = async (e) => {
    e.preventDefault();
    resetErrors();
    try {
      await createUserWithEmailAndPassword(auth, email, password);
      navigate('/upload'); // Redirect to the upload page after successful registration
    } catch (err) {
      handleFirebaseError(err.code); // Pass error code
    }
  };

  // Handle Firebase authentication errors and assign them to specific fields
  const handleFirebaseError = (errorCode) => {
    switch (errorCode) {
      case 'auth/invalid-email':
        setEmailError('Invalid Email');
        break;
      case 'auth/wrong-password':
        setPasswordError('Invalid Password');
        break;
      case 'auth/user-not-found':
        setEmailError('User not found');
        break;
      case 'auth/weak-password':
        setPasswordError('Password should be at least 6 characters');
        break;
      case 'auth/invalid-credential':
        // This error happens when either email or password is invalid
        setEmailError('Invalid Email or Password');
        setPasswordError('Invalid Email or Password');
        break;
      default:
        setError('An unexpected error occurred. Please try again.');
        break;
    }
  };

  // Reset error messages
  const resetErrors = () => {
    setEmailError('');
    setPasswordError('');
    setError('');
  };

  // Handle Google sign-in
  const handleGoogleSignIn = async () => {
    resetErrors();
    const provider = new GoogleAuthProvider();
    try {
      await signInWithPopup(auth, provider);
      navigate('/upload'); // Redirect to the upload page after successful login
    } catch (err) {
      setError(err.message);
    }
  };

  // Handle password reset
  const handlePasswordReset = async () => {
    resetErrors();
    if (!email) {
      setEmailError('Please provide an email address.');
      return;
    }
    try {
      await sendPasswordResetEmail(auth, email);
      setError('Password reset email sent. Please check your inbox.');
    } catch (err) {
      setEmailError(err.message);
    }
  };

  // Handle anonymous guest login
  const handleGuestLogin = async () => {
    resetErrors();
    try {
      await signInAnonymously(auth);
      navigate('/upload'); // Redirect to the upload page after successful anonymous login
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <div className='body-container'>
      <div className='img-container'>
        <img src={loginimg} className='logimg' alt='Login Visual'/>
      </div>
      <div className="login-container">
        <h2 className="login-title">{isRegistering ? 'Register' : 'Login'}</h2>
        
        <form className="login-form" onSubmit={isRegistering ? handleRegister : handleLogin}>
          {error && <p className="error-message">{error}</p>}
          
          {/* Email Input */}
          <label>Email</label>
          <input
            type="email"
            value={email}
            placeholder="Enter email address"
            onChange={(e) => setEmail(e.target.value)}
            required
            className='login-input'
          />
          {/* {emailError && <p className="input-error">{emailError}</p>} Display error under the email input */}
          
          {/* Password Input */}
          <label>Password</label>
          <input
            type="password"
            value={password}
            placeholder="Enter password"
            onChange={(e) => setPassword(e.target.value)}
            required
            className='login-input'
          />
          {passwordError && <p className="input-error">{passwordError}</p>} {/* Display error under the password input */}
          
          {/* Options and Submit Button */}
          {!isRegistering && (
            <div className="login-options">
              <label className="remember-me">
                <input type="checkbox" />
                Remember me
              </label>
              <span className="forgot-password" onClick={handlePasswordReset}>
                Forgot password?
              </span>
            </div>
          )}
          
          <button type="submit" className="login-button">
            {isRegistering ? 'Register' : 'Login'}
          </button>
        </form>
  
        <div className="divider">
          <span>Or connect with</span>
        </div>
  
        <div className="auth-buttons">
          <div>
            <button className="google-signin" onClick={handleGoogleSignIn}>
              {/* Google Icon (SVG) */}
              <svg xmlns="http://www.w3.org/2000/svg" width="36" height="37" viewBox="0 0 36 37" fill="none">
                <g id="Group">
                  <path id="Vector" d="M35.6173 18.905C35.6173 17.72 35.5123 16.595 35.3323 15.5H18.3823V22.265H28.0873C27.6523 24.485 26.3773 26.36 24.4873 27.635V32.135H30.2773C33.6673 29 35.6173 24.38 35.6173 18.905Z" fill="#4285F4"/>
                  <path id="Vector_2" d="M18.3824 36.5001C23.2424 36.5001 27.3074 34.8801 30.2774 32.1351L24.4874 27.6351C22.8674 28.7151 20.8124 29.3751 18.3824 29.3751C13.6874 29.3751 9.71239 26.2101 8.28738 21.9351H2.31738V26.5701C5.27238 32.4501 11.3474 36.5001 18.3824 36.5001Z" fill="#34A853"/>
                  <path id="Vector_3" d="M8.28732 21.9351C7.91232 20.8551 7.71732 19.7 7.71732 18.5C7.71732 17.3 7.92732 16.1451 8.28732 15.0651V10.4301H2.31732C1.08732 12.8601 0.382324 15.59 0.382324 18.5C0.382324 21.41 1.08732 24.1401 2.31732 26.5701L8.28732 21.9351Z" fill="#FBBC05"/>
                  <path id="Vector_4" d="M18.3824 7.625C21.0374 7.625 23.4074 8.54 25.2824 10.325L30.4124 5.195C27.3074 2.285 23.2424 0.5 18.3824 0.5C11.3474 0.5 5.27238 4.55 2.31738 10.43L8.28738 15.065C9.71238 10.79 13.6874 7.625 18.3824 7.625Z" fill="#EA4335"/>
                </g>
              </svg>
            </button>
          </div>
          <button className="guest-login" onClick={handleGuestLogin}>
            Continue as Guest
          </button>
        </div>
  
        <p className="register-link">
          {isRegistering ? 'Already have an account?' : 'Don’t have an account?'}{' '}
          <span onClick={() => setIsRegistering(!isRegistering)}>
            {isRegistering ? 'Login here' : 'Register here'}
          </span>
        </p>
      </div>
    </div>
  );
};

export default Login;
