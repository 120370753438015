import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
// import { getAuth } from "firebase/auth"; // Import Authentication
import { getAuth, GoogleAuthProvider } from 'firebase/auth';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyD33JyUFjugVISjRu_yElDamtvR0-6tBlU",
  authDomain: "prototype-finkraft.firebaseapp.com",
  projectId: "prototype-finkraft",
  storageBucket: "prototype-finkraft.appspot.com",
  messagingSenderId: "283994867558",
  appId: "1:283994867558:web:d5038e6f45625e3c86eaa7",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const storage = getStorage(app);
const auth = getAuth(app); // Initialize Auth
const provider = new GoogleAuthProvider();

export { storage, auth ,provider  }; // Export auth as well
